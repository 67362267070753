.spot-search__select {
    width: 100%;
}

.spot-search__option-text {
    max-width: 100%;
    white-space: normal;
}

.spot-search__option-warning {
    color: var(--polar-red);
    margin-right: var(--g-xs);
}

.spot-search__add-polarsteps-spot-option {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 8px;
    cursor: pointer;
}

.spot-search__add-polarsteps-spot-option:hover {
    background-color: #f0f0f0;
}

.spot-search__add-polarsteps-spot {
    color: #1890ff;
    font-weight: bold;
}
