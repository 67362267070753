.collection-spot-editor__spot-content-card {
    max-width: 582px;
}

.collection-spot-editor__spot-content-labels {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var(--g-s);
}

.collection-spot-editor__spot-content-label-emoji {
    width: 57px;
    margin-right: var(--g-m);
}

.collection-spot-editor__spot-content-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: var(--g-sm);
}

.collection-spot-editor__spot-content-item:last-of-type {
    margin-bottom: var(--g-ml);
}

.ant-input.collection-spot-editor__spot-content-emoji {
    width: 57px;
}

.ant-select.collection-spot-editor__spot-content-text {
    margin-right: var(--g-m);
}
