.sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
}

.sidebar__logo {
    padding: var(--g-ml);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.sidebar__logo img {
    width: 177px;
    height: 34px;
    object-fit: contain;
}

.ant-layout-sider-collapsed .sidebar__logo {
    padding: var(--g-ml) 0;
    justify-content: center;
}

.ant-layout-sider-collapsed .sidebar__logo img {
    width: 100%;
}

.sidebar-user {
    margin-top: auto;
    padding: 40px var(--g-m);
    padding-bottom: 10px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 280px;
    overflow: hidden;
}

.sidebar-user__text {
    color: white;
    margin-left: var(--g-sm);
    flex: 1;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.ant-layout-sider-collapsed .sidebar-user__text {
    opacity: 0;
}

.sidebar__version-container {
    text-align: left;
    width: 280px;
    padding-left: 12px;
    padding-bottom: var(--g-sm);
}
