.map__inner {
    aspect-ratio: 620 / 431;
    max-height: calc(100vh - 650px);
}

.map__editButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--g-ml);
}
