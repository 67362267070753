/* Overrides default Antd Modal styles */
.ant-modal.full-screen-modal {
    width: 100% !important; /* Antd sets inline width */
    height: 100%;
    padding-bottom: 0;
    top: 0;
}

.ant-modal.full-screen-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ant-modal.full-screen-modal .ant-modal-body {
    flex-grow: 1;
    padding: 0;
    position: relative;
}

.ant-modal.full-screen-modal .ant-modal-header {
    padding: 10px 15px;
}

.ant-modal-content .ant-modal-header {
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}

.ant-modal:not(.has-back-button) .ant-modal-header {
    padding: 24px 24px 16px;
}

.ant-modal-header .ant-modal-title {
    font-weight: bold;
    font-size: 22px;
}
