.item {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.itemContent {
    display: flex;
    gap: 16px;
}

.form {
    flex: 1;
}

/* stylelint-disable-next-line selector-class-pattern */
.form :global(.form-value) {
    max-width: 100%;
}

.mediaSelector {
    width: 165px;
    height: 215px;
}
