:root {
    --g-xs: 4px;
    --g-s: 8px;
    --g-sm: 12px;
    --g-m: 16px;
    --g-ml: 24px;
    --g-l: 32px;
    --max-content-width: 1147px;
    --polar-green-6: #52c41a;
    --polar-red: #e34646;
    --darkest-blue: #002e3d;
    --grey-3: #4b5a6c;
    --grey-15: #8a99ad;
    --grey-23: #e7eaee;
    --antd-page-bg: #f0f2f5;
    --antd-border: #f0f0f0;

    /* Map area */
    --search-color: #1890ff;
    --search-color-bg: #1890ff30;
    --interest-color: #eb2f96;
    --interest-color-bg: #eb2f9630;

    /* Good to know section colors */

    --vibe-color: #4fbbc4;
    --stay-color: #775bc8;
    --insider-tip-color: #e08b28;
    --best-time-color: #cc586f;
    --hot-to-get-there-color: #4b997c;
    --need-to-know-color: #807577;
    --extra-1-color: #bf8060;
    --extra-2-color: #f2835b;
}
