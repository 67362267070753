.language-selector {
    min-width: 68px;
}

.language-selector__value {
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
