.avatar-with-name {
    display: flex;
    align-items: center;
}

.avatar-with-name .ant-avatar {
    flex-shrink: 0;
}

.avatar-with-name__name {
    font-size: 16px;
    line-height: 24px;
    margin-left: var(--g-sm);
}
