.image__container {
    position: relative;
}

.image {
    width: 200px;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
}

.table tr,
.table td,
.table a {
    vertical-align: middle;
}
