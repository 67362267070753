.form-field-2 {
    margin-bottom: var(--g-ml);
}

.form-field-2-with-max-value {
    margin-bottom: 2px;
}

.form-field-2__label {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: var(--g-s);
    display: flex;
    align-items: center;
}

.form-field-2__label-icon {
    height: 22px;
    width: 7px;
    margin-right: var(--g-xs);
}

.form-field-2__label-icon img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.form-field-2__max-length {
    text-align: right;
    font-size: 14px;
    line-height: 22px;
    color: rgb(22 8 8 / 25%);
}

.form-value-2__max-length-error {
    color: var(--polar-red);
}

.form-field-2__label-optional {
    color: rgb(22 8 8 / 45%);
}
