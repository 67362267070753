.story-item-editor {
    align-items: center;
}

.story-item-editor__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
}

.story-item-editor__buttons button {
    margin: 4px 0;
}

.story-item-editor__media {
    width: 120px;
    margin-right: 16px;
}

.story-item-editor:not(.is-first-story) .story-item-editor__form {
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.story-item-editor:not(.is-first-story) .story-item-editor__form-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.story-item-editor__form .form-label {
    min-width: 50px;
    width: auto;
}
