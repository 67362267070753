.guide-editor-tips-table tbody > tr.ant-table-row-level-0 {
    background-color: #e6f7ff;
}

.guide-editor-tips-table tbody > tr.ant-table-row-level-0:hover > td {
    background-color: #e6f7ff !important;
}

.guide-editor-tips-table tbody > tr.ant-table-row-level-0 > td {
    padding: var(--g-s) var(--g-m);
}

.guide-editor-tips-table tbody > tr.ant-table-row-level-1 > td {
    padding: var(--g-s) var(--g-s);
    color: rgb(0 0 0 / 65%);
    vertical-align: middle;
}

.guide-editor-tips-table__spot-title {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.guide-editor-tips-table__spot-name {
    font-size: 14px;
    line-height: 20px;
}

.guide-editor-tips-table__user {
    /* Solves weird bug where table creates space */
    vertical-align: initial;
}

.guide-editor-tips-table__user .avatar-with-name__name {
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
}

.guide-editor-tips__date {
    white-space: nowrap;
}

.guide-editor-tips__user {
    display: flex;
    align-items: center;
}

.guide-editor-tips__user-name {
    white-space: nowrap;
}
