.collection-spot-editor__modal {
    max-width: 100%;
}

.collection-spot-editor__modal .ant-modal-content,
.collection-spot-editor__modal .ant-modal-body {
    height: 100%;
}

.collection-spot-editor__modal .ant-modal-body {
    padding: 0;
}

.collection-spot-editor .ant-tabs-nav {
    margin-bottom: 0 !important;
}

.collection-spot-editor {
    height: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.collection-spot-editor__title {
    padding: var(--g-m) var(--g-ml);
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection-spot-editor__title-publish {
    margin-left: auto;
    margin-right: var(--g-s);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.collection-spot-editor__content {
    flex: 1;
    display: flex;
    min-height: 0;
    overflow: hidden;
}

.collection-spot-editor__footer {
    border-top: 1px solid var(--antd-border);
    padding: var(--g-sm);
    background: white;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.collection-spot-editor__footer-review {
    margin-left: auto;
    margin-right: var(--g-s);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.collection-spot-editor__tab-content {
    max-height: calc(
        100vh - 60px - 65px - 38px - 56px
    ); /* This is the size of the modal minus the header, tabs and footer of the modal */

    overflow-y: auto;
}

.collection-spot-tip-title {
    max-width: 700px;
    margin-right: auto;
}

.collection-spot-editor__tips-empty {
    justify-self: center;
    width: 280px;
    text-align: center;
}

.collection-spot-editor__error {
    padding: var(--g-l);
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection-spot-editor__source-radio {
    display: block !important;
    margin-bottom: var(--g-sm) !important;
}

.collection-spot-editor__source-radio:last-child {
    margin-bottom: 0;
}

.collection-spot-editor__photos,
.collection-spot-editor__details,
.collection-spot-editor__spot {
    background-color: var(--antd-page-bg);

    /* padding: var(--g-ml); */
    height: 100%;
}

.collection-spot-editor__details {
    display: grid;
    column-gap: var(--g-ml);
    grid-template-columns: minmax(300px, 1fr) 372px;
}

.collection-spot-editor__tips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
}

.collection-spot-editor__tips-left,
.collection-spot-editor__tips-left-empty,
.collection-spot-editor__tips-right {
    padding: var(--g-ml);
}

.collection-spot-editor__tips-left-empty {
    background-color: var(--antd-page-bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-spot-editor__tips-left {
    background-color: var(--antd-page-bg);
    display: block;
}

.collection-spot-editor__source-link {
    display: flex;
    align-items: center;
}

.collection-spot-editor__last-update {
    font-size: 14px;
    align-items: baseline;
    display: flex;
    color: gray;
}

.collection-spot-editor__details-content,
.collection-spot-editor__spot-content,
.collection-spot-editor__photos-content {
    padding: var(--g-ml);
}
