.root {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    transform: scale(1);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}

.root:hover {
    background: white;
    z-index: 1000;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    transform: scale(1.5);
}

.preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.tools {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    padding: 10px;
    font-size: 9px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
}

.tools__userInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.root:hover .tools {
    opacity: 1;
}
