.ps-spot-editor {
    width: 475px;
}

.ps-spot-editor__secondary-text {
    font-size: 14px;
    line-height: 22px;
    color: rgb(0 0 0 / 45%);
}

.ps-spot-editor__map {
    width: 100%;
    height: 288px;
}

.ps-spot-editor__map-spot-marker {
    height: 24px;
    width: 24px;
    pointer-events: none;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    border: 2px solid white;
}

.ps-spot-editor__map-spot-marker img {
    height: 14px;
    width: 14px;
}

.ps-spot-editor__phone-container {
    position: relative;
}

.ps-spot-editor__phone-warning {
    position: absolute;
    top: 9px;
    right: 10px;
    z-index: 10;
}

/* Overwriting phone input styles */
.ps-spot-editor input {
    font-size: 16px;
    padding: 6.5px 11px;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    color: rgb(0 0 0 / 85%);
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    transition: all 0.3s;
    font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue',
        Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
}

.ps-spot-editor input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.ps-spot-editor input::placeholder {
    color: #d9d9d9;
}
