.collection-spot-editor__selected-tips {
    flex: 1;
    width: 100%;
    align-self: flex-start;
}

.collection-spot-editor__selected-tips-title {
    height: 24px;
}

.selected-tip__translation-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: var(--g-xs);
    padding-right: var(--g-s);
}

.selected-tip__form-field {
    margin-bottom: 0;
}

.selected-tip__translation-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: var(--g-s);
    padding-right: var(--g-xs);
}
