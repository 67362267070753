.login-widget {
    min-height: 300px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-widget__title {
    display: grid;
}

.login-widget__subtitle {
    display: flex;
    justify-self: flex-end;
    margin-top: -18px;
    color: var(--polar-red);
    font-family: Lato;
}

.login-widget__card .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-widget__card {
    box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;
}

.login-widget__card svg g {
    fill: var(--polar-red);
}

.login-widget__body {
    display: flex;
    flex-direction: column;
    gap: var(--g-s);
    margin-top: var(--g-l);
}
