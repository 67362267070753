.form-field {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: var(--g-m);
}

.form-field:last-of-type {
    margin-bottom: 0;
}

.form-label {
    width: 160px;
    min-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: var(--g-sm);
    text-align: right;
}

.form-label-align-top {
    align-self: flex-start;
}

.form-value__length {
    font-size: 12px;
    line-height: 14px;
    color: rgb(0 0 0 / 45%);
}

.form-value__length {
    text-align: right;
    position: absolute;
    right: 12px;
    bottom: 10px;
    pointer-events: none;
    z-index: 1;
    padding: var(--g-xs);
    padding-left: var(--g-s);
    min-width: 64px;
    background: linear-gradient(
        to right,
        transparent,
        rgb(255 255 255 / 50%) 10%,
        white
    );
}

.form-value__length-error {
    color: var(--polar-red);
}

.form-value {
    min-width: 200px;
    max-width: 500px;
    flex: 1;
    min-height: 40px;
    line-height: 40px;
    position: relative;
}

.form-field-with-max-value .ant-input {
    padding-right: 70px;
}

/* Hack to make Textareas (that can't have size in AntD 3x) look like Large Inputs */
.form-field__textarea.ant-input {
    line-height: 28px !important;
    font-size: 16px;
}

.form-error {
    font-size: 10px;
    position: absolute;
    bottom: -16px;
    left: 0;
}
