.add-collection-button-container {
    position: absolute;
    bottom: var(--g-m);
    right: var(--g-ml);
    z-index: 5;
}

.collections-section {
    margin-bottom: 40px;
}

.guide-editor-collections__filter-button-container {
    position: relative;
}

.guide-editor-collections__filter-button {
    position: absolute;
    top: 0;
    right: 0;
}

.guide-editor-collections__option.is-useless {
    color: rgb(0 0 0 / 45%);
}

.guide-editor-basic-info__container {
    display: grid;
    column-gap: var(--g-ml);
    grid-template-columns: minmax(300px, 1fr) 372px;
}

.form-field__datepicker {
    width: 100%;
}

.guide-editor-tips-nested-table .ant-spin-nested-loading .ant-table {
    margin: -16px 0 !important;
}

.guide-editor-tips-nested-table tbody > tr {
    background-color: unset !important;
}

.guide-editor-tips-nested-table tbody > tr > td {
    vertical-align: middle;
}

.guide-editor-tips-spot-reviewed {
    color: #1890ff !important;
    border-color: #1890ff !important;
}
