.collection {
    --collection-spot-width: 205px;
    --collection-spot-height: 216px;
    --collection-min-height: 220px;

    margin-top: 24px;
}

.collection:not(.top-collection) .ant-card-extra,
.collection:not(.top-collection) .ant-card-head-title {
    padding: 8px;
}

.collection__header-spot-selector .spot-selector {
    width: 305px;
}

.collection__title {
    display: flex;
    align-items: center;
}

.collection__title .collection-status {
    flex-shrink: 0;
}

.collection__header-title {
    max-width: 100%;
    display: flex;
}

.collection__content {
    min-height: var(--collection-min-height);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.collection__content-loading {
    position: absolute;
    inset: 0;
    z-index: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    background: rgb(255 255 255 / 30%);
}

.collection__content-spots {
    width: 100%;
    padding: var(--g-m);
    display: grid;
    grid-template-columns: repeat(auto-fit, var(--collection-spot-width));
    grid-gap: 10px;
    position: relative;
}

.collection__content-spots-loading {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: rgb(255 255 255 / 30%);
    z-index: 5;
}

.collection__content-spot-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection__content-spots-empty {
    width: 100%;
    min-height: var(--collection-min-height);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.collection-status {
    --collection-status-size: 6px;

    border-radius: 50%;
    height: var(--collection-status-size);
    width: var(--collection-status-size);
    background-color: var(--grey-15);
}

.collection-status-published {
    background-color: var(--polar-green-6);
}

.collection-status-draft {
    background-color: '#FAAD47';
}

.collection__content-loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    background: rgb(255 255 255 / 50%);
    z-index: 1;
}
