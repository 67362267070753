.guide-editor-map {
}

.guide-editor-map__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--g-m);
    margin-bottom: var(--g-m);
}

.guide-editor-map__filter {
    display: flex;
    align-items: center;
    gap: var(--g-s);
}

.guide-editor-map__collection-filter {
    width: 200px;
}

.guide-editor-map__map-container {
    display: flex;
    position: relative;
    height: 100%;
}

.guide-editor-map__actions {
    z-index: 20;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: var(--g-s);
    display: flex;
    flex-direction: column;
}

.guide-editor-map__button-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: var(--g-m);
}

.guide-editor-map__button-label {
    display: flex;
    align-items: center;
    gap: var(--g-s);
}

.guide-editor-map__legend-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid var(--grey-23);
    border-radius: var(--g-xs);
    padding: var(--g-xs);
}

.guide-editor-map__area-legend {
    padding: 2px;
    padding: var(--g-xs);
}

.guide-editor-map__area-legend--interest {
    background-color: #eb2f9620;
}

.guide-editor-map__area-legend--search {
    background-color: #1890ff20;
}

.guide-editor-map__area-legend--interest div {
    border-top: 1px dashed #eb2f96;
}

.guide-editor-map__area-legend--search div {
    border-top: 1px dashed #1890ff;
}

.guide-editor-map__map-container .guide-editor-map__actions button {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 0;
}

.guide-editor-map__map-container .guide-editor-map__actions button:last-child {
    border-top-color: transparent;
}

.guide-editor-map__map-container .guide-editor-map__actions button:hover {
    border-top-color: #40a9ff;
}

.guide-editor-map__tooltip {
    display: flex;
    flex-direction: column;
    gap: var(--g-xs);
}

.guide-editor-map__tooltip .ant-typography-secondary.ant-typography-secondary {
    color: white;
}
