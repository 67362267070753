.collection-spot-tip {
    padding: 0 var(--g-s);
}

.collection-spot-tip .ant-typography {
    font-family: 'PT Serif', serif;
    font-weight: normal;
    color: var(--grey-3);
    margin-bottom: 0;
    font-style: normal;
    font-size: 11px;
    line-height: 17px;
    vertical-align: middle;
}

.collection-spot-tip__user {
    display: inline-flex;
    font-family: Lato;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 4px;
    margin-right: var(--g-xs);
}

.collection-spot-tip__empty {
    font-family: 'PT Serif', serif;
    color: var(--grey-15);
    font-style: normal;
    font-size: 11px;
    line-height: 17px;
    margin-top: var(--g-xs);
}
