/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable selector-class-pattern */

.item {
    display: grid;
    gap: 16px;
    grid-template-columns: 165px 1fr;
}

.itemContent {
    display: flex;
    gap: 16px;
}

.form {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--g-m);
}

.form :global(.form-value) {
    max-width: 100%;
}

.doubleFieldRow {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 8px;
}

.noMargin {
    margin: 0;
}

.colorSelect.vibe {
    :global(.ant-select-selection-item) {
        color: var(--vibe-color);
    }
}

.colorSelect.stay {
    :global(.ant-select-selection-item) {
        color: var(--stay-color);
    }
}

.colorSelect.insiderTip {
    :global(.ant-select-selection-item) {
        color: var(--insider-tip-color);
    }
}

.colorSelect.bestTimeToVisit {
    :global(.ant-select-selection-item) {
        color: var(--best-time-color);
    }
}

.colorSelect.howToGetThere {
    :global(.ant-select-selection-item) {
        color: var(--hot-to-get-there-color);
    }
}

.colorSelect.needToKnow {
    :global(.ant-select-selection-item) {
        color: var(--need-to-know-color);
    }
}

.colorSelect.extra1 {
    :global(.ant-select-selection-item) {
        color: var(--extra-1-color);
    }
}

.colorSelect.extra2 {
    :global(.ant-select-selection-item) {
        color: var(--extra-2-color);
    }
}

.colorOption.vibe {
    :global(.ant-select-item-option-content) {
        color: var(--vibe-color);
    }
}

.colorOption.stay {
    :global(.ant-select-item-option-content) {
        color: var(--stay-color);
    }
}

.colorOption.insiderTip {
    :global(.ant-select-item-option-content) {
        color: var(--insider-tip-color);
    }
}

.colorOption.bestTimeToVisit {
    :global(.ant-select-item-option-content) {
        color: var(--best-time-color);
    }
}

.colorOption.howToGetThere {
    :global(.ant-select-item-option-content) {
        color: var(--hot-to-get-there-color);
    }
}

.colorOption.needToKnow {
    :global(.ant-select-item-option-content) {
        color: var(--need-to-know-color);
    }
}

.colorOption.extra1 {
    :global(.ant-select-item-option-content) {
        color: var(--extra-1-color);
    }
}

.colorOption.extra2 {
    :global(.ant-select-item-option-content) {
        color: var(--extra-2-color);
    }
}
