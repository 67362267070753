/** Styles to make content height fit tabs container height, and show scroll if needed. Also limiting max width inside */
.tabs {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    flex: 1;
}

.tabs .ant-tabs-content {
    flex: 1;
    width: 100%;
}

.tabs .ant-tabs-tabpane {
    height: 100%;
}

.tab-pane {
    /* with footer */
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    min-width: 100%;
    flex: 1;
    position: relative;
    height: 100%;
}

.tab-content {
    /* with footer */
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    flex: 1;
    max-height: 100%;
}

.tab-content-limit-width {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
    flex: 1;
}

.full-page-tab-content {
    padding-bottom: var(--g-m);
    padding-top: var(--g-ml);
    overflow: auto;
}

.tab-content-with-footer {
    max-height: calc(100% - var(--full-page-footer-height));
    padding-bottom: 72px;
}

.full-page-tab-content-limit-width {
    max-width: var(--max-content-width);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--g-ml);
}

.full-page-tabs .ant-tabs-content-holder,
.normal-tabs .ant-tabs-content-holder {
    display: flex;
}

/** Footer */
.full-page-tabs-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background: white;
}

.full-page-tabs-footer .max-content-width {
    background: white;
    padding: var(--g-sm) var(--g-m);
    display: flex;
}

.full-page-tabs-footer .max-content-width > * {
    margin-right: var(--g-s);
}

.full-page-tabs-footer .max-content-width > *:last-child {
    margin-right: 0;
}

/** Loading */
.full-page-tabs-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    z-index: 10;
    background-color: rgb(255 255 255 / 20%);
}
