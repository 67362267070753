:root {
    --media-selector__popover-width: 620px;
    --media-selector__popover-height: 500px;
}

.root {
    position: relative;
}

.trigger {
    border: none;
    background-color: transparent;
    padding: 0;
}

.container {
    z-index: 1999;
    font-family: Lato, sans-serif;
    padding: var(--g-m);
    background-color: white;
    box-shadow: rgb(99 99 99 / 20%) 0 2px 8px 0;
}

.container a {
    color: #1677ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.container a:hover {
    color: #69b1ff;
}

.arrow {
    fill: white;
}

.image {
    max-width: var(--media-selector__popover-width);
    max-height: var(--media-selector__popover-height);
    object-fit: cover;
    object-position: center center;
}

.text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        180deg,
        rgb(0 0 0 / 0%) 0%,
        rgb(0 0 0 / 56%) 100%
    );
    color: white;
    font-size: 12px;
    line-height: 16px;
    padding: var(--g-sm);
    padding-top: var(--g-l);
}
