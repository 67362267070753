.collection-spot {
    --collection-spot-width: 205px;
    --collection-spot-height: 216px;

    margin: var(--g-s) var(--collection__content-spots-gutter);
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border-radius: var(--g-s);
    height: var(--collection-spot-height);
    width: var(--collection-spot-width);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-out;
}

.collection-spot.is-dimmed {
    opacity: 0.4;
}

.collection-spot__dragging {
    cursor: grabbing;

    /* Overwrite default react-sortable-hoc, to allow for cursor showing, it is not needed anyhow */
    pointer-events: auto !important;
}

.collection-spot-content {
    height: var(--collection-spot-height);
    user-select: none;
}

.collection-spot-image {
    height: 110px;
    background: var(--antd-page-bg);
    position: relative;
}

.collection-spot-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.collection-spot-title {
    padding: 0 var(--g-s);
    padding-top: var(--g-xs);
}

.collection-spot-title-text {
    font-family: Lato, sans-serif;
    font-weight: bold;
}

.collection-spot-paragraph.ant-typography {
    margin-bottom: 0;
}

.collection-spot__unpublished-overlay {
    position: absolute;
    inset: 0;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 65%);
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: white;
}

.collection-spot-image-decorator {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    background-image: url('./spot-image-border-down.png');
    background-size: contain;
    background-repeat: repeat-x;
    background-position: center 1px;
}

.collection-spot-meta {
    position: absolute;
    display: flex;
    width: 100%;
    z-index: 2;
    padding: var(--g-s);
    cursor: default;
    user-select: none;
}

.collection-spot-labels {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    overflow: hidden;
}

.collection-spot-label {
    top: var(--g-s);
    left: var(--g-s);
    z-index: 10;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: var(--g-xs);
    margin-bottom: var(--g-xs);
    overflow: hidden;
}

.spot-label {
    position: relative;
    border-radius: 10px;
    font-size: 9px;
    color: white;
    padding: 0 8px;
    text-transform: uppercase;
    overflow: hidden;
    height: 16px;
}

.spot-label.bg-white {
    background-color: white;
    color: var(--darkest-blue);
}

.spot-label-bg {
    position: absolute;
    z-index: 1;
    inset: 0;
    opacity: 0.8;
}

.spot-label-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    z-index: 2;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 7px;
    text-transform: uppercase;
}

.spot-label-content span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.spot-label-icon {
    height: 12px;
    width: 12px;
    margin-right: 4px;
    flex-shrink: 0;
}

.spot-label-icon img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.collection-spot-review-banner {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    color: white;
    background: rgb(24 144 255 / 98%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    padding: 8px 4px;
}
