.collection-spot-editor__photos {
    max-height: calc(100vh - 223px);
    overflow: auto;
}

.collection-spot-editor__photos-title {
    display: flex;
    align-items: center;
}

.collection-spot-editor__photos .media-selector-image-list-item {
    --image-size: 175px;
}

.collection-spot-editor__photos--loading {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collection-spot-editor__photos--no-photos {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: 1s ease 0s normal forwards 1 collection-spot-editor__fade-in;
}

@keyframes collection-spot-editor__fade-in {
    0% {
        opacity: 0;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.collection-spot-editor__photos-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #595959;
    padding: var(--g-sm) var(--g-ml);
}

.ant-card.collection-spot-editor__photos-featured {
    max-width: 370px;
    min-width: 370px;
    margin-bottom: var(--g-ml);
}

.ant-card.collection-spot-editor__photos-custom {
    margin-bottom: var(--g-ml);
}
