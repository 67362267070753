body.meraki-body {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.line {
    display: flex;
}

.line-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.column-center {
    display: flex;
    flex-direction: column;
}

.grow-full-flex {
    flex: 1;
}

.max-content-width {
    max-width: var(--max-content-width);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.custom-actions-card .ant-card-actions > li > span {
    cursor: default;
}

/* Object oriented margins */
.mt-xs {
    margin-top: var(--g-xs);
}

.mb-xs {
    margin-bottom: var(--g-xs);
}

.ml-xs {
    margin-left: var(--g-xs);
}

.mr-xs {
    margin-right: var(--g-xs);
}

.mt-s {
    margin-top: var(--g-s);
}

.mb-s {
    margin-bottom: var(--g-s);
}

.ml-s {
    margin-left: var(--g-s);
}

.mr-s {
    margin-right: var(--g-s);
}

.mt-sm {
    margin-top: var(--g-sm);
}

.mb-sm {
    margin-bottom: var(--g-sm);
}

.ml-sm {
    margin-left: var(--g-sm);
}

.mr-sm {
    margin-right: var(--g-sm);
}

.mt-m {
    margin-top: var(--g-m);
}

.mb-m {
    margin-bottom: var(--g-m);
}

.ml-m {
    margin-left: var(--g-m);
}

.mr-m {
    margin-right: var(--g-m);
}

.mt-ml {
    margin-top: var(--g-ml);
}

.mb-ml {
    margin-bottom: var(--g-ml);
}

.ml-ml {
    margin-left: var(--g-ml);
}

.mr-ml {
    margin-right: var(--g-ml);
}

.mt-l {
    margin-top: var(--g-l);
}

.mb-l {
    margin-bottom: var(--g-l);
}

.ml-l {
    margin-left: var(--g-l);
}

.mr-l {
    margin-right: var(--g-l);
}
