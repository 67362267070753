/* stylelint-disable selector-pseudo-class-no-unknown */
/* stylelint-disable selector-class-pattern */

:root {
    --sortable-buttons-size: 74px;
    --label-size: 100px;
    --label-margin: var(--g-sm);
    --item-title-size: 165px;
}

.item {
    display: grid;
    gap: 16px;
    grid-template-columns: 165px 1fr;
}

.itemContent {
    display: flex;
    gap: 16px;
}

.form {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--g-m);
}

.form :global(.form-value) {
    max-width: 100%;
}

.header {
    display: grid;
    gap: 16px;
    grid-template-columns:
        calc(var(--item-title-size) + var(--label-size) + var(--label-margin))
        1fr;
}

.headerField {
    :global(.form-value) {
        max-width: calc(100% - var(--sortable-buttons-size));
    }
}

.headerHelpText {
    color: var(--grey-15);
}

.noMargin {
    margin: 0;
}

.spotsTitle {
    margin-top: 12px;
    margin-left: calc(var(--label-size) + var(--label-margin));
}

.spotEmptyState {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
