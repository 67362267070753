.cse-photos__uploading-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

.uploading-photo__container {
    position: relative;
    display: flex;
}

.uploading-photo__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
}

.uploading-photo {
    height: 200px;
    width: 200px;
    object-fit: contain;
    opacity: 0.4;
}

.uploading-photo__container.is-completed .uploading-photo {
    opacity: 1;
}

.cse-photos__media-list {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
}

.cse-photos__media-item-img {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.cse-photos__media-item {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.cse-photos__media-item:hover {
    background-color: var(--grey-23);
}

.cse-photos__media-item-delete {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.cse-photos__media-item-warning {
    position: absolute;
    inset: 0;
    background: rgb(255 255 255 / 70%);
    display: flex;
    align-items: center;
    justify-content: center;
}
