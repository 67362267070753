.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 24px;
    border-bottom: 1px solid var(--grey-23);
}

.item.secondary {
    padding: 0;
    border-bottom: none;
    gap: 0;
}

.item.secondary:not(:last-child) {
    margin-bottom: 24px;
}

.header {
    padding: 24px;
    border-bottom: 1px solid var(--grey-23);
}

.children {
    flex: 1;
}

.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 24px;
}

.footer.secondary {
    justify-content: flex-end;
}

.footer.secondary button {
    margin-right: 40px;
}

.emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 48px;
}

.emptyStateText {
    font-size: 18px;
    font-weight: 500;
    color: var(--grey-15);
}

.badge {
    margin-left: 8px;
}

.rightDivider {
    border-right: 1px solid var(--grey-23);
}
