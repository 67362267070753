.root {
    display: grid;
    grid-template-areas:
        'info cover'
        'map map';
    grid-template-columns: 1fr 270px;
    grid-template-rows: auto 1fr;
    gap: var(--g-ml);
    flex: 1 1;
    padding: var(--g-ml);
    align-items: flex-start;
}

.mapCard {
    grid-area: map;
}
