.full-page {
    max-height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow-y: auto;
}

.full-page-content {
    flex: 1;
    position: relative;
    padding-top: var(--g-ml);
}

.full-page-content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.full-page-header-tools-filter {
    max-width: 400px;
}

.full-page-header-tools-add-button {
    margin-left: auto;
}

.full-page-error {
    max-width: 400px;
    width: 400px;
}

.full-page-error-button {
    padding-top: var(--g-sm);
    display: flex;
    justify-content: center;
}

.full-page-footer {
    background: white;
    padding: var(--g-sm) var(--g-m);
    display: flex;
}

.full-page-footer > * {
    margin-right: var(--g-s);
}

.full-page-footer > *:last-child {
    margin-right: 0;
}
