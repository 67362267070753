.selectable-tip {
    padding: 10px 0;
    border-top: 1px solid var(--antd-border);
}

.selectable-tip:last-of-type {
    border-bottom: 1px solid var(--antd-border);
}

.is-selectable.selectable-tip {
    cursor: pointer;
}
