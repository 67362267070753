.title {
    font-style: normal;
    font-weight: bold;
    margin: 0;
}

.title-page {
    font-size: 28px;
    line-height: 34px;
}

.title-modal {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.title-section {
    font-size: 16px;
    line-height: 24px;
}

.title-subsection {
    font-size: 16px;
    line-height: 24px;
}
