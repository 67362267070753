.spot-editor-all-tips {
    flex: 1;
}

.spot-editor-all-tips-header {
    padding-bottom: var(--g-m);
}

.spot-editor-all-tips-empty {
    text-align: center;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
}
