.noResults {
    padding: 12px;
}

.searchTools {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;
    align-items: center;
}

.mediaList {
    display: grid;
    grid-template-columns: repeat(5, minmax(200px, 1fr));
    gap: 12px;
}

.mediaList li {
    list-style: none;
    aspect-ratio: 1 / 1;
    display: flex;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}
